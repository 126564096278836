<template>
  <footer class="bg-primary-950 py-[80px] text-white">
    <app-page-wrapper
      class="grid gap-12 lg:grid-cols-[repeat(var(--columnCount),1fr)]"
      :style="{
        '--columnCount': sections.length + 1,
      }"
    >
      <div>
        <app-image
          alt="Pollen logo"
          class="mb-6 cursor-emoji-[🐝]"
          src="/images/logo_full_white.svg"
        />

        <p class="text-sm">
          Organisme de formation enregistré sous le numéro 11922636792.
        </p>

        <app-image
          alt="Qualiopi logo"
          class="my-4"
          src="/images/qualiopi_logo.svg"
        />

        <p class="text-sm">
          <a class="underline" :href="qualiopiCertificate" target="_blank"
            >La certification qualité</a
          >
          a été délivrée au titre de la catégorie d’actions suivante : ACTIONS
          DE FORMATION
        </p>

        <p class="my-4 text-sm">contact@heypollen.com</p>

        <brand-social-medias-list />
      </div>

      <section v-for="section in sections" :key="section.title">
        <h3 class="mb-4 text-xl font-bold">{{ section.title }}</h3>

        <ul class="grid gap-2">
          <li v-for="item in section.items" :key="item.label">
            <nuxt-link
              active-class="!opacity-100 font-semibold"
              class="block opacity-70 transition duration-300 hover:translate-x-1 hover:opacity-100"
              :href="item.href"
              :target="item.href ? '_blank' : undefined"
              :to="item.to"
              >{{ item.label }}</nuxt-link
            >
          </li>
        </ul>
      </section>
    </app-page-wrapper>
    <p class="mt-16 text-center text-sm">
      {{ copyrightMention }}
    </p>
  </footer>
</template>

<script lang="ts" setup>
import type { RouteLocationRaw } from "#vue-router";
import BrandSocialMediasList from "~/business-areas/brand/components/brand-social-medias-list.vue";
import type { I18nMessage } from "~/core/i18n/i18n.model";
import { useCopyrightMention } from "~/layouts/_includes/composables/main-footer.hook";

const copyrightMention = useCopyrightMention();
const {
  public: {
    apps: { jobs: jobsUrl },
    documents: { qualiopiCertificate },
  },
} = useRuntimeConfig();

const sections: {
  title: I18nMessage;
  items: { label: I18nMessage; to?: RouteLocationRaw; href?: string }[];
}[] = [
  {
    title: "Entreprises",
    items: [
      {
        label: "Vos talents sur Pollen",
        to: "/enterprise",
      },
      {
        label: "Programmes sur mesure",
        to: "/bespoke",
      },
    ],
  },
  {
    title: "Trainers",
    items: [
      {
        label: "Méthodologie",
        to: "/methodology",
      },
      {
        label: "Devenir trainer",
        to: "/become-trainer",
      },
    ],
  },
  {
    title: "Talents",
    items: [
      {
        label: "Toutes les formations",
        to: "/search",
      },
      { label: "Financement par mon employeur", to: "/getting-financed" },
    ],
  },
  {
    title: "Pollen",
    items: [
      {
        label: "Rejoindre l'équipe",
        href: jobsUrl,
      },
      {
        label: "Blog",
        to: "/blog",
      },
      {
        label: "CGU & CGV",
        to: "/content/terms-and-conditions",
      },
      {
        label: "Politique de confidentialité",
        to: "/content/privacy-policy",
      },
      {
        label: "Mentions légales",
        to: "/content/legal-notices",
      },
      {
        label: "Règlement intérieur",
        to: "/content/rules-of-procedure",
      },
    ],
  },
];
</script>
